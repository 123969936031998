/**
 * @ngdoc service
 * @name angular-wlc-core.service:MessagingService
 * @description
 *
 * Internal messaging related event handler to avoid hard $rootScope.emit/broadcast
 *
 * @example
 *
 * <pre>
 *     var subscribeId = MessagingService.subscribe(
 *         UserService.events.AUTH_SUCCEEDED,
 *         function (name) {
 *             alert('Hi, ' + name);
 *         }
 *     );
 *
 *     MessagingService.publish(
 *         UserService.events.AUTH_SUCCEEDED,
 *         [
 *             'John'
 *         ]
 *     );
 *
 *     MessagingService.unsubscribe(subscribeId);
 * </pre>
 */

(function (module) {
    'use strict';

    function MessagingService(MessagingEvents) {
        var self = this;

        var subscriptions = {};

        /**
         * @ngdoc property
         * @name events
         * @propertyOf angular-wlc-core.service:MessagingService
         * @description
         *
         * Object of avaliable events for subscription <br />
         * Format: MessagingService.events[eventName] - {Array} of {Callbable}
         */
        self.events = MessagingEvents;

        /**
         * @ngdoc method
         * @name addEvents
         * @methodOf angular-wlc-core.service:MessagingService
         * @param {Object} events Object with events
         * @description
         *
         * Add events to global MessagingEvents pool
         */
        self.addEvents = function (events) {
            self.events = angular.extend(self.events, events);
        };

        /**
         * @ngdoc method
         * @name getSubscriptions
         * @methodOf angular-wlc-core.service:MessagingService
         * @description
         *
         * Get all available subscriptions
         */
        self.getSubscriptions = function () {
            return subscriptions;
        };

        /**
         * @ngdoc method
         * @name subscribe
         * @methodOf angular-wlc-core.service:MessagingService
         * @param {String} topic Subscribe topic
         * @param {Callable} callback Subscribe callback
         * @returns {Array} Subscribe identifier (handler) which later can be used for unsubscribe
         * @description
         *
         * Subscribe to specific topic
         *
         */
        self.subscribe = function (topic, callback) {
            if (!subscriptions[topic]) {
                subscriptions[topic] = [];
            }
            subscriptions[topic].push(callback);
            return [topic, callback];
        };

        /**
         * @ngdoc method
         * @name publish
         * @methodOf angular-wlc-core.service:MessagingService
         * @param {String} topic Event topic
         * @param {Array} args Event arguments
         * @description
         *
         * Publish event with specific topic and arguments <br/>
         * This event will be received by subscribers for event topic
         */
        self.publish = function (topic, args) {
            if (subscriptions[topic] && subscriptions[topic].length) {
                angular.forEach(subscriptions[topic], function (callback) {
                    callback.apply(null, args || []);
                });
            }
        };

        /**
         * @ngdoc method
         * @name unsubscribe
         * @methodOf angular-wlc-core.service:MessagingService
         * @param {Array} handle Handler which is generated by `subscribe` method
         * @description
         *
         * Unsubscribe with handler. Hander is returned from `subscribe` method
         */
        self.unsubscribe = function (handle) {
            var t = handle[0];
            if (subscriptions[t]) {
                for (var x = 0; x < subscriptions[t].length; x++) {
                    if (subscriptions[t][x] === handle[1]) {
                        subscriptions[t].splice(x, 1);
                    }
                }
            }
        };
    }

    module.service('MessagingService', ['MessagingEvents', MessagingService]);
})(angular.module('app'));

'use strict';
(app => {
    app.directive('wlcGetPost', [
        '$sce',
        '$compile',
        'WPRestAPIService',
        '$timeout',
        function (
            $sce,
            $compile,
            WPRestAPIService,
            $timeout) {

            return {
                restrict: 'A',
                template: '<div data-wlc-bind-compile="data"></div>',
                scope: true,
                link: ($scope, $element, $attrs) => {
                    const slug = $attrs.wlcGetPost;
                    $scope.data = '';
                    WPRestAPIService.getPostData(slug).then( result => {
                        // let content = $compile(result[0] && result[0].content && result[0].content.rendered)($scope);
                        let content = result[0] && result[0].content && result[0].content.rendered;
                        $scope.data =  content; //(content) ? $sce.trustAsHtml(content) : '';
                        if ($attrs.wlcGetPost === 'partners_faq') {
                            $timeout(() => {
                                const tds = angular.element($element).find('td');
                                angular.forEach(tds, td => {
                                    const header = angular.element(td).find('h3');
                                    angular.element(header).on('click', () => {
                                        angular.element(td).toggleClass('wlc-show');
                                    });
                                });
                            });
                        }
                    }, () => {

                    });
                }
            };
        }]);
})(angular.module('app'));

'use strict';

(function(module) {

    function SessionCacheService(CacheServiceImplement) {
        var self = this;

        self.config = {
            cacheName: 'application-session',
            appConfigName: 'sessionCache',
            storageMode: 'sessionStorage',
            storagePrefix: 'angular-cache.session.'
        };

        angular.extend(this.__proto__, CacheServiceImplement.factory(self));
    }

    module
        .service('SessionCacheService', ['CacheServiceImplement', SessionCacheService]);
})(angular.module('app'));

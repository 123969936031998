(function (module) {
    module.config([
        '$stateProvider',
        function ($stateProvider) {

            $stateProvider.state('app', {
                abstract: true,
                url: '/:locale',
                controller: 'ApplicationComponent',
                controllerAs: 'app',
                params: {
                    locale: ['LanguageService', (LanguageService) => LanguageService.getCurrentLanguage().code]
                },
                resolve: {
                    localeLoader: ['LanguageService', '$stateParams', function (LanguageService, $stateParams) {
                        LanguageService.setCurrentLanguage($stateParams.locale);
                    }]
                }
            });
        }]);
})(angular.module('app'));

'use strict';
(app => {
    app.directive('wlcIcons', [
        'appConfig',
        '$templateCache',
        '$sce',
        function (
            appConfig,
            $templateCache,$sce) {

            return {
                restrict: 'A',
                scope: true,
                link: ($scope, $element, $attrs) => {
                    const itemsList = appConfig[$attrs.wlcIcons];

                    $scope.payments = [];

                    if (!angular.isArray(itemsList)) {
                        return;
                    }

                    angular.forEach(itemsList, item => {
                        if ($templateCache.get('svg/' + item + '.html')) {
                            $scope.payments.push($sce.trustAsHtml($templateCache.get('svg/' + item + '.html')));
                        }
                    });
                }
            };
        }]);
})(angular.module('app'));

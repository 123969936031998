'use strict';
(app => {
    app.directive('wlcWpPostsList', [
        'appConfig',
        '$templateCache',
        '$sce',
        'WPRestAPIService',
        function (
            appConfig,
            $templateCache,
            $sce,
            WPRestAPIService) {

            return {
                restrict: 'A',
                scope: true,
                link: ($scope, $element, $attrs) => {
                    let category = $attrs.wlcWpPostsList;
                    $scope.items = [];
                    WPRestAPIService.getPostsListByCategory(category, {order: 'asc'}).then( result => {
                        $scope.items = result;
                    }, error => {});
                }
            };
        }]);
})(angular.module('app'));

'use strict';

(function(module) {

    module
        .service('LocalCacheService', [
            'CacheServiceImplement',
            function (
                CacheServiceImplement) {
            var self = this;

            self.config = {
                cacheName: 'application-local',
                appConfigName: 'localCache',
                storageMode: 'localStorage',
                storagePrefix: 'angular-cache.local.'
            };

            angular.extend(this.__proto__, CacheServiceImplement.factory(self));
        }]);
})(angular.module('app'));

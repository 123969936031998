'use strict';

(function(module) {

    function CacheServiceImplement(CacheFactory, appConfig) {
        var _self = this;

        _self.factory = function(self){
            var _config = {
                cacheName: 'application-local',
                cacheFlushInterval: null,
                capacity: Number.MAX_VALUE,
                deleteOnExpire: 'none',
                disabled: false,
                onExpire: null,
                maxAge: Number.MAX_VALUE,
                recycleFreq: 1000,
                storageImpl: null,
                storageMode: 'localStorage',
                storagePrefix: 'angular-cache.caches.',
                storeOnReject: false,
                storeOnResolve: false
            };

            var _appConfig =(appConfig[self.config.appConfigName]) ?  appConfig[self.config.appConfigName] : {};

            self.config = angular.extend(_config, _appConfig, self.config);

            self.cache = CacheFactory.get(self.config.cacheName);
            if (!self.cache) {
                self.cache = CacheFactory.createCache(self.config.cacheName, self.config);
            }

            self.get = function(name){
                return self.cache.get(name);
            };

            self.remove = function(name){
                return self.cache.remove(name);
            };

            self.set = function(name, value, options){
                return self.cache.put(name, value, options);
            };

            self.keys = function() {
                return self.cache.keys();
            };
        };
    }

    module
        .service('CacheServiceImplement', ['CacheFactory', 'appConfig', CacheServiceImplement]);
})(angular.module('app'));

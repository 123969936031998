(function (module) {
    module.config([
        '$stateProvider',
        'gettext',
        function ($stateProvider, gettext) {

            $stateProvider.state('app.home', {
                url: '',
                params: {
                    sectionName: 'Home'
                },
                templateUrl: 'components/home.html',
                // controller: 'HomeComponentController',
                // controllerAs: 'bh',
                ncyBreadcrumb: {
                    label: gettext('Home page')
                }

            });
        }]);
})(angular.module('app'));

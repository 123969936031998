/**
 * @ngdoc service
 * @name angular-wlc-core.service:LanguageService
 * @description
 *
 * Service for manipulation with system wide languages.<br/>
 * <br/>
 * Language object fields: <br/>
 * <b>code</b> - Language code <br/>
 * <b>label</b> - Language label <br/>
 * <b>iso3</b> - Language iso3 code <br/>
 * <br/>
 * <br/>
 * JSON Object:
 * <pre>
 * {
 *     code: 'en',
 *     label: 'English',
 *     iso3: 'eng'
 * }
 * </pre>
 */
'use strict';

(function (module) {

    function LanguageService($cookies, gettextCatalog, tmhDynamicLocale, appConfig, MessagingService) {
        var self = this;

        var supportedLanguages = appConfig.languages || [
            {code: 'en', label: 'English', iso3: 'eng'}
        ];

        var currentLanguage = null;

        /**
         * @ngdoc event
         * @name events.LANGUAGE_CHANGED
         * @eventOf angular-wlc-core.service:LanguageService
         * @description
         *
         * Language changed event generated when user switches language via setCurrentLanguage method <br/>
         * Example using {@link angular-wlc-core.service:MessagingService MessagingService}:
         *
         * @example
         *
         * <pre>
         * var onLanguageChangedHandler = MessagingService.subscribe(
         *      LanguageService.events.LANGUAGE_CHANGED, function(language) {
         *          console.log('CurrentLanguage', language);
         *      }
         *  );
         *
         *  $scope.$on('$destroy', function() {
         *      MessagingService.unsubscribe(onLanguageChangedHandler);
         *  });
         * </pre>
         */
        self.events = {
            'LANGUAGE_CHANGED': 'LanguageChanged@angular-wlc-core.LanguageService'
        };

        self.getDefaultLanguage = function () {
            var language = appConfig.language || 'en';
            return (self.getLanguageByCode(language)) ? language : 'en';
        };

        /**
         * @ngdoc method
         * @name getSupportedLanguages
         * @methodOf angular-wlc-core.service:LanguageService
         * @returns {Array} Array with supported languages
         * @description
         *
         * Get system wide supported language
         */
        self.getSupportedLanguages = function () {
            return supportedLanguages;
        };

        /**
         * @ngdoc method
         * @name getLanguageByCode
         * @methodOf angular-wlc-core.service:LanguageService
         * @param {String} code Language code
         * @returns {Object|null} Language object or null
         * @description
         *
         * Get supported language by it's code
         */
        self.getLanguageByCode = function (code) {
            var foundLanguages = supportedLanguages.filter(function (language) {
                return language.code === code;
            });

            if (foundLanguages.length > 0) {
                return foundLanguages[0];
            }

            return null;
        };

        /**
         * @ngdoc method
         * @name getLanguageLabelByCode
         * @methodOf angular-wlc-core.service:LanguageService
         * @param {String} code Language code
         * @returns {String|null} Found language label or null
         * @description
         *
         * Get supported language label by it's code
         */
        self.getLanguageLabelByCode = function (code) {
            var foundLanguage = self.getLanguageByCode(code);
            if (foundLanguage) {
                return foundLanguage.label;
            }
            return null;
        };

        /**
         * @ngdoc method
         * @name getCurrentLanguage
         * @methodOf angular-wlc-core.service:LanguageService
         * @returns {Object} Current language object
         * @description
         *
         * Get current language object
         */
        self.getCurrentLanguage = function () {
            if (!currentLanguage) {
                currentLanguage = self.getLanguageByCode(self.getDefaultLanguage());
                appConfig.language = currentLanguage.code;
            }
            return currentLanguage;
        };

        /**
         * @ngdoc method
         * @name setCurrentLanguage
         * @methodOf angular-wlc-core.service:LanguageService
         * @param {String} code Language code
         * @returns {$Promise} Language change object
         * @description
         *
         * Set current language by code
         */
        self.setCurrentLanguage = function (code) {
            var init = (code) ? false : true;
            if (!code) {
                code = self.getDefaultLanguage();
            }

            if (currentLanguage && code === currentLanguage.code) {
                return currentLanguage;
            }

            var foundLanguage = self.getLanguageByCode(code);
            if (!foundLanguage) {
                return null;
            }

            currentLanguage = foundLanguage;
            appConfig.language = currentLanguage.code;

            tmhDynamicLocale.set(code);
            var promise = gettextCatalog.loadRemote((appConfig.baseUrl || '') + '/static/dist/languages/' + code + '.json').then(function () {
                gettextCatalog.setCurrentLanguage(code);
                if (!init) {
                    var now = new Date(),
                        // this will set the expiration to 12 months
                        exp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());

                    $cookies.put('sitelang', code, {
                        expires: exp,
                        path: '/'
                    });

                    setTimeout(function () {
                        MessagingService.publish(self.events.LANGUAGE_CHANGED, [code]);
                    }, 0);
                }
            });

            return promise;
        };

        /**
         * @ngdoc method
         * @name loadCurrentLanguage
         * @methodOf angular-wlc-core.service:LanguageService
         * @returns {$Promise} Result promise
         * @description
         *
         * Load/Reload current language translations for 'gettext'
         */
        self.loadCurrentLanguage = function () {
            var code = self.getCurrentLanguage().code;
            tmhDynamicLocale.set(code);
            var promise = gettextCatalog.loadRemote((appConfig.baseUrl || '') + '/static/dist/languages/' + code + '.json').then(function () {
                if (gettextCatalog.getCurrentLanguage() !== code) {
                    gettextCatalog.setCurrentLanguage(code);
                }
                return code;
            });
            return promise;
        };
    }

    module.service('LanguageService', ['$cookies', 'gettextCatalog', 'tmhDynamicLocale', 'appConfig', 'MessagingService', LanguageService]);
})(angular.module('app'));

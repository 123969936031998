(function (app) {
    app.run(['appConfig', (appConfig) => {
        angular.extend(appConfig, {
            paymentSystems: [
                'bitcoin',
                'skrill',
                'visa',
                'mastercard',
                'neteller',
                'moneta',
                'yandexmoney',
                'qiwiwallet'
            ],
            merchants: [
                'evoplay',
                'wazdan',
                'playngo',
                'wacs',
                'yggdrasil',
                'spinomenal',
                'pragmaticplay',
                'asiagaming',
                'sagaming',
                'quickspin',
                'digitain',
                'endorphina',
                'amatic',
                'habanero',
                'booming-games',
                'pariplay',
                'goldenrace',
                'tom-horn',
                'betsoft',
                'bet-games',
                'microgaming',
                'evolution'
            ]
        });
    }]);
})(angular.module('app'));

'use strict';

(function (app) {

    app.controller('ScrollCtrl', [
        '$scope',
        '$location',
        'anchorSmoothScroll',

        function ($scope,
                  $location,
                  anchorSmoothScroll) {

            $scope.gotoElement = function (eID) {
                anchorSmoothScroll.scrollTo(eID);
            }
        }]
    );
})(angular.module('app'));
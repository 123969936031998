/**
 * @ngdoc service
 * @name angular-wlc-core.service:ModalService
 * @description
 *
 * Show modal messages and dialogs service
 *
 * Default controller: ModalServiceController
 * For use with controllerAs: controller: 'ModalServiceController as dialog'
 *
 * Options:
 * <pre>
 * {
 *     templateUrl: 'your template url'.
 *     size: 'size of dialog/message xs,sm,md,lg,xl',
 *     controller: 'Controller code or name.',
 *     resolve: 'Object with values to resolve'
 * }
 * </pre>
 */
'use strict';

(function (module) {

    function ModalServiceController($uibModalInstance, $scope, dialogOptions) {
        var self = this;
        $scope.dialog = self;

        self.instance = $uibModalInstance;

        angular.extend($scope, dialogOptions);

        self.closeDialog = function (result, close) {
            return (close) ? $uibModalInstance.close(result) : $uibModalInstance.dismiss(result || 'cancel');
        };
    }

    function ModalService($uibModal, $uibModalStack, ModalParams) {

        this.showDialog = (options = {}) => {

            if (options.dismissAll) {
                $uibModalStack.dismissAll();
            }

            let modalResolve = options.resolve || {};
            modalResolve.dialogOptions = () => options;

            let modalOptions = {
                    templateUrl: options.templateUrl || 'dialogs/modal.html',
                    size: options.size || 'xl',
                    controller: options.controller || 'ModalServiceController',
                    resolve: modalResolve
                },
                modalParams = angular.extend(options, modalOptions);

            ModalParams.set(modalParams);

            let modal = $uibModal.open(modalParams);

            modal.opened.then( () => {
                ModalParams.set(null);
            }, () => {
                ModalParams.set(null);
            });

            return modal;
        };


    }

    function ModalParamsProvider() {
        let $params = {};

        this.get = () => $params;

        this.set = params => $params = params;

        this.$get = () => this;
    }

    module
        .controller('ModalServiceController', ['$uibModalInstance', '$scope', 'dialogOptions', ModalServiceController])
        .provider('ModalParams', [ModalParamsProvider])
        .service('ModalService', ['$uibModal', '$uibModalStack', 'ModalParams', ModalService]);

})(angular.module('app'));

(function (module) {
    module.controller('ApplicationComponent', [
        '$state',
        'appConfig',
        function (
            $state,
            appConfig) {
            this.appConfig = appConfig;
        }]);

})(angular.module('app'));

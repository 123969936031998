(function (app) {
    app.config(['appConfig', (appConfig) => {
        angular.extend(appConfig, {
            mainWPUrl: 'https://www.alexandracasino.com',
            affiliateURL: 'https://partners.alexandracasino.com',
            languages: [
                {code: 'en', label: 'English', iso3: 'eng'},
                {code: 'ru', label: 'Русский', iso3: 'rus'}],
            language: 'en'
        });
    }]);
})(angular.module('app'));

'use strict';
((module) => {
    module.directive('wlcGoToWebSite', [
        'LanguageService',
        'appConfig',
        function (
            LanguageService,
            appConfig) {

            return {
                restrict: 'A',
                scope: true,
                link: function ($scope, $element, $attrs) {
                    $scope.url = appConfig.mainWPUrl + '/' + LanguageService.getCurrentLanguage().code;
                    $scope.url += ($attrs.wlcGoToWebSite) ? '/' + $attrs.wlcGoToWebSite : '';
                    angular.element($element).on('click', () => {
                        window.location.href = $scope.url;
                    });
                }
            };
        }]);
})(angular.module('app'));


(function (module) {
    module.directive('wlcBindCompile', ['$compile', ($compile) => {
        return {
            restrict: 'A',
            link: ($scope, $element, $attrs) => {
                const showContent = (html) => {
                    if (html instanceof Object || html instanceof Array) {
                        $element.append(angular.element(html));
                    } else if (typeof html === 'string' && html.length) {
                        const compiledHtml = $compile(html)($scope);
                        $element.append(compiledHtml);
                    }
                };

                $scope.$watch($attrs.wlcBindCompile, (newVal) => {
                    showContent(newVal);
                });
            }
        };
    }]);
})(angular.module('app'));

'use strict';
var app = angular.module('app', [
    'ngRoute',
    'ngCookies',
    'ngResource',
    'ngAnimate',
    'ngSanitize',
    'ui.router',
    'ui.bootstrap',
    'gettext',
    'angularUtils.directives.dirPagination',
    'tmh.dynamicLocale',
    'ncy-angular-breadcrumb',
    'angular-cache'
], function ($locationProvider) {
    $locationProvider.html5Mode(true);
});

app.constant('appConfig', {
    adminMode: false
});

app.config(['tmhDynamicLocaleProvider', function(tmhDynamicLocaleProvider) {
    tmhDynamicLocaleProvider.localeLocationPattern('https://cdnjs.cloudflare.com/ajax/libs/angular-i18n/' +
            angular.version.full+'/angular-locale_{{locale}}.js');
}]);

app.run([
    'gettextCatalog',
    'LanguageService',
    function (gettextCatalog, LanguageService) {
        gettextCatalog.setCurrentLanguage(LanguageService.getCurrentLanguage().code);
    }
]);

'use strict';

(function (app) {

    app.service('WPRestAPIService', [
        '$q',
        '$resource',
        'LanguageService',
        'LocalCacheService',
        'appConfig',
        '$http',
        '$sce',
        function ($q,
                  $resource,
                  LanguageService,
                  LocalCacheService,
                  appConfig,
                  $http,
                  $sce) {

            const urlPrefix = appConfig.mainWPUrl + '/content//wp-json/wp/v2/',
                requestUrls = {
                    categories: urlPrefix + 'categories',
                    tags: urlPrefix + 'tags',
                    posts: urlPrefix + 'posts'
                };

            /**
             *
             * @param type
             * @param params
             */
            this.getData = (type, params) => {
                if (!requestUrls[type]) {
                    return;
                }

                params = angular.extend({
                    lang: LanguageService.getCurrentLanguage().code
                }, params);
                let deferred = $q.defer(),
                    url = $sce.trustAsResourceUrl(requestUrls[type]),
                    cacheKey = url + JSON.stringify(params),
                    cacheData = LocalCacheService.get(cacheKey);
                    // cacheData = false;

                if (cacheData) {
                    deferred.resolve(cacheData);
                } else {
                    $http.jsonp(url, {params: params, jsonpCallbackParam: '_jsonp'}).then( data => {
                        let result;
                        if (angular.isArray(data.data) && data.data.length > 0) {
                            result = data.data.map( item => {
                                if (item.title) {
                                    item.title.rendered = item.title.rendered.replace(/&#([0-9]*);/, (match, p1) => String.fromCharCode(p1));
                                }
                                if (item.content) {
                                    item.content.rendered = item.content.rendered.replace(/&#([0-9]*);/, (match, p1) => String.fromCharCode(p1));
                                }
                                return item;
                            });
                            LocalCacheService.set(cacheKey, result, {maxAge: 10 * 60 * 1000});
                            deferred.resolve(result);
                        } else {
                            deferred.reject();
                        }
                    }, error => deferred.reject(error));
                }
                return deferred.promise;
            };


            /**
             * {
            *  tags: [],
            *  post: 19 - для получения категорий отдельной статьи
            * }
             */
            this.getCategories = params => {
                return this.getData('categories', params);
            };

            /**
             * {
            *  news: [],
            *  tags: [],
            *  pageCount: 1,
            *  currentPage: 1
            * }
             */
            this.getTags = params => {
                return this.getData('tags', params);
            };

            /**
             *
             * @param params
             *  {
            *      page: 2,
            *      perPage: 4, - каким количеством ограничиться для вывода
            *      categories: <identifier> - статьи брать только жддя данной категории,
            *      tags: <identifier> - отфильтровать по тегу
            *  }
             */
            this.getPosts = params => {
                return this.getData('posts', params);
            };

            this.getPostData = (slug, params) => this.getData('posts', angular.extend({
                slug: slug
            }, params || {}));

            this.getSubCategories = (slug, params) => {
                let deferred = $q.defer();
                this.getCategories().then( result => {
                    angular.forEach(result, item => {
                        if (item.slug === slug) {
                            this.getCategories({parent: item.id}).then( result => {
                                deferred.resolve(result);
                            }, error => {
                                deferred.reject(error);
                            });

                        }
                    });
                }, error => {
                    deferred.reject(error);
                });
                return deferred.promise;
            };

            this.getPostsListByCategory = (category, params) => {
                let cat = category.split(':'),
                    deferred = $q.defer(),
                    getList = (categoryId) => {
                        this.getPosts(angular.extend(params || {}, {categories: [categoryId]})).then( result => {
                            deferred.resolve(result);
                        }, error => deferred.reject(error) );
                    };
                this.getSubCategories(cat[0]).then( result => {
                    if (!cat[1]) {
                        getList(result[0].id, params);
                    } else {
                        angular.forEach(result, subCategory =>
                        {
                            if (subCategory.slug === cat[1]) {
                                getList(subCategory.id, params);
                            }
                        });
                    }
                }, error => deferred.reject(error) );
                return deferred.promise;
            };

            // this.getPostsListByCategory('partners:features').then(result => console.log(result));

        }]);

})(angular.module('app'));

'use strict';
(app => {
    app.directive('wlcShowPost', [
        'appConfig',
        '$templateCache',
        '$sce',
        'WPRestAPIService',
        'ModalService',
        '$rootScope',
        function (
            appConfig,
            $templateCache,
            $sce,
            WPRestAPIService,
            ModalService,
            $rootScope) {

            return {
                restrict: 'A',
                link: ($scope, $element, $attrs) => {
                    angular.element($element).on('click', event => {
                        event.preventDefault();
                        let slug = $attrs.wlcShowPost,
                            modalParams = {};
                        if (!slug) {
                            return;
                        }

                        let $newScope = $rootScope.$new();

                        modalParams.scope = $newScope;

                        WPRestAPIService.getPostData(slug).then( result => {
                            let data = result[0];
                            data.content.rendered = $sce.trustAsHtml(data.content.rendered);
                            $newScope.data = data;
                        }, error => {

                        });

                        let modal = ModalService.showDialog(modalParams);
                        modal.result.then(function () {
                            $newScope.$destroy();
                        }, function(){
                            $newScope.$destroy();
                        });

                    });
                }
            };
        }]);
})(angular.module('app'));

/**
 * @ngdoc object
 * @name angular-wlc-core.object:MessagingEvents
 * @description
 * 
 * Globaly avaliable events for MessagingService (initial setup)  
 * Others events can be added during application lifetime using  
 */
'use strict';

(function (module) {

    module
        .constant('MessagingEvents', {
            'CHANGE_LANGUAGE': 'CHANGE_LANGUAGE',
            'LANGUAGE_CHANGED': 'LANGUAGE_CHANGED',
            'SHOW_MESSAGE_DIALOG': 'SHOW_MESSAGE_DIALOG',
            'SHOW_MODAL_DIALOG': 'SHOW_MODAL_DIALOG',
            'CLOSE_ALL_MODAL_DIALOGS': 'CLOSE_ALL_MODAL_DIALOGS',
        });
})(angular.module('app'));

'use strict';
(app => {
    app.directive('wlcLanguageSelector', [
        'appConfig',
        'LanguageService',
        '$state',
        function (
            appConfig,
            LanguageService,
            $state) {

            return {
                restrict: 'A',
                scope: true,
                templateUrl: 'directives/language-selector.html',
                link: ($scope, $element, $attrs) => {
                    $scope.currentLanguage = LanguageService.getCurrentLanguage().code;
                    $scope.supportedLanguages = LanguageService.getSupportedLanguages();
                    $scope.setLanguage = (language) => {
                        $state.go('app.home', {locale: language.code});
                    };
                }
            };
        }]);
})(angular.module('app'));
